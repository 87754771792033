import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { FaArrowAltCircleDown } from "react-icons/fa"
const Uiux = () => {
  return (
    <Layout>
      <Seo
        title="User Interface(UI)/User Experience(UX) design and branding agency in Delaware"
        description="TheSoftCode is a Delaware based UI(User Interface)/UX(User Experience) design agency that creates best-in-class digital experiences to help businesses succeed in today's digital world."
        keyword="User Interface, User Experience, UI, UX"
      />
      <Container fluid className="min-vh-100">
        <br />
        <br />
        <br />
        <br />
        <br />
        <Row>
          <Col
            md={6}
            sm={12}
            className="align-items-center text-center my-auto"
          >
            <h1 className="text3d fs-3 mb-5">UI/UX DESIGNING</h1>
          </Col>
          <Col md={6} sm={12}>
            <StaticImage
              // This is a presentational image, so the alt should be an empty string
              alt="UI-UX Designing poster"
              // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
              src={"../images/UIUX.png"}
              placeholder={"tracedSVG"}
              layout={"constrained"}
              formats={["auto", "webp", "avif"]}
            />
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <Row className="text-center text-white">
          <Col>
            <h2 className="h2 luminance">
              We Design User Interface(UI) that creates entailing User
              Experience(UX)
            </h2>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <br />
        <div className="position-absolute bottom-0 start-50 text-white mb-1">
          <FaArrowAltCircleDown className="bouncearrow" />
        </div>
      </Container>
      <Container fluid className="bg-white">
        <br />
        <br />
        <br />
        <br />
        <br />
        <Container className="align-items-center fs-5 text-center">
          <p
            className="bg-text fs-5 text-wrap"
            data-bg-text="UI/UX"
            data-aos="zoom-in-right"
          >
            The creative procedure that is employed into effective GUI design
            demands an in-depth consideration of a user’s needs, including an
            appreciation of their industry expectations. Whether the design is
            for mobile app or a web, the best GUI designs are the ones that are
            able to integrate the form and function. GUI is an instrument for
            enabling users to achieve their overall goals, and GUI designers
            that place trends and style above functionality and reliability
            won’t be able to provide a product with well-built long-term value.
            <br />
            <br />
            We are experienced in this field and have developed efficient user
            interface design tools for our clients in a variety of sectors. We
            can work with you through some or all stages of a project’s growth
            cycle, and the best part is that we are flexible enough to adjust
            our creative and design procedures for any kind of client. Whatever
            the peculiarities of your business are, we will make sure that your
            users are productive and build novel solutions that will help you to
            achieve your long-term goals.
            <br />
            <br />
            Our heart is on a design language that integrates the standard
            principles of thriving design along with technology and innovation.
            Our objective is to build a design system that enables for an
            integrated user experience. All of the apparatus are fashioned in
            the same style, and can easily be incorporated into new projects and
            pages, allowing you to build new solutions. We work very much with
            your team members, as well as your Agency, to design and build style
            guidelines, color palettes, and layouts that mirror your corporate
            culture while also improving the usability of product UI. We observe
            the following process from research stage through to integration
            support, all to ensure that we don’t miss out on anything..
          </p>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Container fluid>
        <br />
        <br />
        <h2
          className="text-center bg-textlight"
          data-aos="flip-left"
        >
          RESEARCH
        </h2>
        <br />
        <br />
        <Container className="text-white text-wrap text-center fs-5">
          We know that user-friendly design begins with setting goals and
          objectives. It prepares the platform for design, strategy, content,
          and information architecture (IA). Most business priorities often
          ignore and lack the reality of genuine user needs. We do not join the
          bandwagon, what is amazing and matters to users aren’t always what we
          “consider” to be amazing. At the beginning of research, we guide you
          properly to ascertain knowledge gaps and line up your assumptions with
          the real user expectations. We assist you to spot a strategy to
          support both your business goals and user needs. Our research-oriented
          and analytical approach offers premeditated insights on your
          customers’ responses to and overall interactions with your
          application. We choose the research methods that will work for you
          based on the resources and time available.
        </Container>
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Container fluid className="bg-white">
        <br />
        <br />
        <h2 className="text-center bg-textdark" data-aos="flip-up">
          ARCHITECTURE & WIREFRAMES
        </h2>
        <br />
        <br />
        <Container className="align-items-center fs-5 text-center text-wrap">
          The chief corner stone of the UX design is to ensure that the visual
          configuration and appearance of information on an application is
          discerning and concurrent with the existing user rational models and
          expectations. Softcode ensures that the right information is offered
          in the right manner, in the right place, and at the right time also.
          Wireframing is the best way to picture data structures and symbolize
          UI features. It allows useful communication among team members and,
          more significantly, enables for early testing of navigation,
          workflows, and overall data configuration.
        </Container>
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Container fluid>
        <br />
        <br />
        <h2
          className="text-center bg-textlight"
          data-aos="flip-left"
        >
          LOOK AND FEEL
        </h2>
        <br />
        <br />
        <Container className="text-white text-wrap text-center fs-5">
          We know that the visual appeal of your product has an far-reaching
          impact on the users. A product's Information Architecture IA is very
          important but it doesn't aid engagement on its own. We utilize
          user-friendly design to deliver a predictable, cohesive, and desirable
          effect on the target audience. We are dedicated to targeting users on
          a more emotional and deeper level, we ensure that the user experience
          is more insightful. The change in experience is achieved by bringing
          out an emotional reaction in the user which is related to their
          actions and achievements. One of the basic aims of UX design is to add
          background to the natural behavior of users and, by so doing, to offer
          them a story that they can extract from the experience. We equally
          ensure that your product is a part of your brand’s united entity and
          is offered with the requisite degree of integrity.
        </Container>
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Container fluid className="bg-white">
        <br />
        <br />
        <h2 className="text-center bg-textdark" data-aos="flip-up">
          CREATIVE GUIDELINES
        </h2>
        <br />
        <br />
        <Container className="align-items-center fs-5 text-center text-wrap">
          To guarantee seamless future development and expansion, we will
          develop a set of guidelines that offer an overview of design fonts and
          styles, layout structure, patterns, colour palette and a list of
          technical constraints and considerations. We want to assure you that
          by investing in UI design, you can boost up your returns on investment
          ROI radically as you improve the UX making your app’s functionality
          more handy and intuitive.
          <br />
          <br />
          <br />
          <b>
            If you want to get the best GUI design, then you’ve come to the
            right place. We look forward to working with you to build UIs that
            are unrivalled.
          </b>
        </Container>
        <br />
        <br />
      </Container>
    </Layout>
  )
}

export default Uiux
